export const BACKGROUND	= "#282a36";
export const CURRENTLINE	= "#44475a";
export const FOREGROUND	= "#f8f8f2";
export const COMMENT	= "#6272a4";
export const CYAN	= "#8be9fd";
export const GREEN	= "#50fa7b";
export const ORANGE = "#ffb86c";
export const PINK	= "#ff79c6";
export const PURPLE = "#bd93f9";
export const RED	= "#ff5555";
export const YELLOW	= "#f1fa8c";